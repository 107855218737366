<template>
    <img src="@/assets/sun.webp" style="display:none;" alt="" />
    <img src="@/assets/moon.webp" style="display:none;" alt="" />
    <img src="@/assets/mountains.webp" style="display:none;" alt="" />
    <img src="@/assets/mountains-dark.webp" style="display:none;" alt="" />
</template>

<script>
  export default {
    name: 'PreLoad'
  }
</script>

<style lang="scss">
</style>
